<template>
  <div v-if="teamStats" class="team-stats d-flex align-center flex-wrap">
    <div>
    </div>
    <div>
      <v-chip class="members-count stat-chip-style">
        Members: {{teamStats.members}}
      </v-chip>
      <v-chip class="active-nominations stat-chip-style">
        Active nominations: {{ teamStats.outstandingNominations }}
      </v-chip>
    </div>
    <div>
      <v-chip class="comment-last-updated stat-chip-style">
        Comment Last Updated: {{ lastCommentUpdateTime }}
      </v-chip>
      <v-chip class="document-last-updated stat-chip-style">
        Document Last Updated: {{ lastDocumentUpdateTime }}
      </v-chip>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { dateTimeMomentFormat } from "../../utils/utils";

export default {
  name: "TeamStats",

  computed: {
    ...mapState("teamsModule", ["teamStats"]),

    lastCommentUpdateTime() {
      return (this.teamStats || {}).lastCommentUpdateTime
        ? moment(this.teamStats.lastCommentUpdateTime)
          .format(dateTimeMomentFormat)
        : "Never";
    },

    lastDocumentUpdateTime() {
      return (this.teamStats || {}).lastDocumentUpdateTime
        ? moment(this.teamStats.lastDocumentUpdateTime)
          .format(dateTimeMomentFormat)
        : "Never";
    },
  },
};
</script>

<style lang="scss">
.team-stats {
  .stat-chip-style {
    background: white !important;
    border: 1px solid var(--v-primary-base);
    margin-bottom: 8px;
    margin-right: 8px;
  }
  .description {
    cursor: pointer;
  }
  p {
    margin-bottom: 0;
  }
}
</style>
